body {
  margin: 0;
  font-family: 'MCQGlobal_W_Rg', 'MCQGlobal_W_Bd', 'Roboto', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

table,
td,
tr,
th {
  text-align: center;
  /* background: #fff; */
}

/*version : v0001*/
/*******************************************************************************
 font
*******************************************************************************/

@font-face {
  font-family: 'MCQGlobal_W_Bd';
  src:  url('../fonts/MCQGlobal/MCQGlobal_W_Bd.woff') format('woff');
  /*    font-weight: normal;*/
  font-style: normal;
}

@font-face {
  font-family: 'MCQGlobal_W_Rg';
  src: url('../fonts/MCQGlobal/MCQGlobal_W_Rg.woff') format('woff');
  font-style: normal;
}

@font-face {
  font-family: 'MCQGlobal_W_Md';
  src: url('../fonts/MCQGlobal/MCQGlobal_W_Md.woff') format('woff');
  font-style: normal;
}

@font-face {
  font-family: 'DBHeaventLight';
  src: url('../fonts/DBHeavent/DB Heavent Li v3.2.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'DBHeaventMedium';
  src: url('../fonts/DBHeavent/DB Heavent Med v3.2.ttf') format('truetype');
  font-style: normal;
}
@font-face {
  font-family: 'MCQ_Global_App_Medium';
  src: url('../fonts/App/MCQGlobal_A_Md.ttf') format('truetype');
  font-style: normal;
}
@font-face {
  font-family: 'MCQ_Global_App';
  src: url('../fonts/App/MCQGlobal_A_Rg.ttf') format('truetype');
  font-style: normal;
}
@font-face {
  font-family: 'MCQ_Global_App_light';
  src: url('../fonts/App/MCQGlobal_A_Lt.ttf') format('truetype');
  font-style: normal;
}
@font-face {
  font-family: 'MCQ_Global_App_BOLD';
  src: url('../fonts/App/MCQGlobal_A_Bd.ttf') format('truetype');
  font-style: normal;
}
@font-face {
  font-family: 'MCQGlobal_A_Md';
  src: url('../fonts/App/MCQGlobal_A_Md.ttf') format('truetype');
  font-style: normal;
}







/*******************************************************************************
overide bootstrap - body
*******************************************************************************/
body {
  /*    font-family:'Roboto', sans-serif, Arial;*/
  font-family: 'MCQGlobal_W_Rg', 'MCQGlobal_W_Bd', 'Roboto', Arial, sans-serif;
  font-size: 16px;
  color: #333333;
  background-color: #F3F3F4;
}

/*******************************************************************************
overide bootstrap - heading
*******************************************************************************/
h1,
h2,
h3,
h4,
h5,
h6,
p {
  color: #333333;
  line-height: normal
}

h3 {
  font-size: 26px;
}

h5 {
  font-size: 16px;
}

h2.small,
h2 small {
  font-size: 26px;
}

p.small {
  font-size: 12px;
  font-weight: normal;
}

.text-bold {
  font-family: 'MCQGlobal_W_Md';
}

/*******************************************************************************
overide bootstrap - table
*******************************************************************************/
.table>tbody>tr>td,
.table>tbody>tr>th,
.table>tfoot>tr>td,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>thead>tr>th {
  border-top: 0;
  padding: 7px;
}

.table>thead>tr>th {
  font-family: "MCQGlobal_W_Md";
  font-weight: normal;
  line-height: normal;
  border-bottom: 1px solid #FECC0A;
}

/* #today_table .bgcolor-08 td {
  background-color: #F5F6F7;
} */

/* .table-striped>tbody>tr:nth-of-type(odd) {
  background-color: #F5F6F7;
} */
.table-striped>tbody>tr {
  background-color: #F5F6F7;
}

.table-striped-inverse>tbody>tr:nth-of-type(even) {
  background-color: #F5F6F7;
}

.table-striped-inverse>tbody>tr:first-child {
  border-top: 0;
}

.table-closed>tbody tr:first-child {
  border-top: 1px solid #FECC0A
}

.table-closed tbody tr:last-child {
  border-bottom: 1px solid #FECC0A
}

.table-fixed {
  table-layout: fixed;
}

.table-noHeaderborder>thead>tr>th {
  border-bottom: 0;
}

/*******************************************************************************
overide bootstrap - navbar
*******************************************************************************/
.navbar {
  margin-bottom: 0;
  z-index:99999;
}

.navbar-inverse {
  background-color: #000;
}

.navbar-inverse .navbar-brand,
.navbar-inverse .navbar-nav>li>a {
  color: #fff
}

.navbar-inverse .navbar-brand>img {
  display: inline-block
}

.navbar-inverse.navbar-larger .navbar-brand {
  height: 71px;
  padding: 13px 10px;
}

.navbar-inverse.navbar-larger .navbar-toggle {
  margin-top: 18px;
  margin-bottom: 18px;
}

.navbar-inverse.navbar-larger .navbar-nav {
  padding-top: 13px;
  /* padding-bottom: 10px; */
}
.navbar-nav > li{
  height:63px;
  /* padding-bottom: 10px; */
}
.navbar-inverse.navbar-small .navbar-brand>img {
  width: 160px;
}

.navbar-inverse.navbar-small .navbar-brand {
  font-size: 16px;
  height: 51px;
  padding: 5px 10px;
}

.navbar-inverse.navbar-small .navbar-nav {
  padding-top: 0;
  padding-bottom: 0;
}

.navbar-inverse,
.navbar-nav,
.navbar-brand,
.nav-brand img,
.navbar-toggle {
  transition: all 1s;
  -moz-transition: all 1s;
  -webkit-transition: all 1s;
  -o-transition: all 1s;
  z-index: 9999999;
}

.navbar-inverse .navbar-toggle:focus,
.navbar-inverse .navbar-toggle:hover {
  background-color: #000;
}

.navbar-collapse .nav a {
  font-family: "MCQGlobal_W_Md";
  font-size: 15px;
}

.navbar-header {
  margin-left: 10px;
  margin-right: 10px;
}

.navbar-header .nav-icon {
  float: right;
  padding-top: 8px;
  padding-bottom: 8px;
  text-decoration: none;
  margin-right: 5px;
}

.navbar-header a:hover,
.navbar-header .a:focus,
.navbar-header .a:visited {
  color: #fff !important;
}

.nav .li-icon .nav-icon {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.nav .li-icon .nav-icon.icon-phone {
  padding-top: 18px;
  padding-bottom: 12px;
}

.nav .li-icon:last-child .nav-icon {
  padding-left: 8px !important;
}

.nav .li-icon .nav-icon.icon-lang {
  padding-top: 8px;
  padding-bottom: 3px;
}

.nav .li-icon .nav-icon.icon-lang-lg {
  /*    padding-top:8px;
      padding-bottom:3px;*/
  margin-top: 8px;
  margin-left: 8px;
}

.nav-icon {
  font-family: "fontawesome" !important;
  color: #fff !important;
  font-size: 25px !important;
  font-weight: normal !important;
  text-decoration: inherit;
  cursor: pointer;
}

/* .icon-search::before {
  content: "\f002";
}
.icon-phone::before {
  content: "\f095";
} */
.icon-email::before {
  content: "\f003";
}

.icon-lang-lg {
  display: block;
  width: 36px;
  height: 36px;
}

.icon-lang-xs {
  display: block;
  width: 20px;
  height: 20px;
}

.icon-subscribe-xs {
  display: block;
  width: 24px;
  height: 24px;
}

.icon-fb-xs {
  display: block;
  width: 24px;
  height: 24px;
  /* background: url("../img/bullet/icon-fb-xs.png") center center no-repeat */
  background: url("../img/bullet/Facebook-logo-blue-circle-large-transparent-png.png") center center no-repeat;
  background-size: 24px 24px;
}

.icon-tg-xs {
  display: block;
  width: 24px;
  height: 24px;
  background: url("../img/bullet/icon-tg-xs.png") center center no-repeat;
}

.icon-hotline-xs {
  display: block;
  width: 24px;
  height: 24px;
  border-radius: 5px;
  background: url("../img/bullet/icon-hotline.png") center center no-repeat;
  background-size: 18px 18px;
  background-color: #fecc0a;
  /*background:url("../img/bullet/icon-hotline-xs.png") center center no-repeat*/
}

.icon-line-xs {
  display: block;
  width: 24px;
  height: 24px;
  background: url("../img/bullet/icon-line-xs.png") center center no-repeat
}

.icon-lang-lg:lang(en) {
  background: url("../img/bullet/flag-uk-lg.png") center center no-repeat
}

.icon-lang-lg:lang(th) {
  background: url("../img/bullet/flag-th-lg.png") center center no-repeat
}

.icon-lang-lg.othr:lang(en) {
  background: url("../img/bullet/flag-th-lg.png") center center no-repeat
}

.icon-lang-lg.othr:lang(th) {
  background: url("../img/bullet/flag-uk-lg.png") center center no-repeat
}

.icon-lang-xs:lang(en) {
  background: url("../img/bullet/flag-th-xs.png") center center no-repeat
}

.icon-lang-xs:lang(th) {
  background: url("../img/bullet/flag-uk-xs.png") center center no-repeat
}

.icon-subscribe-xs {
  background: url("../img/bullet/subscribe-xs.png") center center no-repeat
}

.nav-icon-tooltip {
  display: inline-block;
  position: absolute;
}

.nav-icon-tooltip:before {
  position: absolute;
  top: -13px;
  right: 0;
  width: 0;
  height: 0;
  vertical-align: middle;
  content: " ";
  border-right: 14px solid transparent;
  border-bottom: 14px solid #666;
  border-left: 14px solid transparent;
}

.center.nav-icon-tooltip:before {
  left: 50%;
  margin-left: -14px;
}

.icon-tooltip-bg {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #666;
  right: 0;
  bottom: 0;
}

.icon-tooltip-content {
  position: relative;
  color: #FFFFFF;
  padding: 0px 10px;
  font-size: 14px;
}

.icon-tooltip-content a {
  color: #FFFFFF;
  font-size: 14px;
  text-decoration: underline;
  font-weight: normal !important;
}

.icon-tooltip-content .form-control,
icon-tooltip-content .form-control:focus {
  border: 0;
  color: #fff;
  background-color: transparent;
  height: auto;
  width: 200px;
  border-bottom: 2px solid #fff;
  padding: 12px 0 0 0;
  margin-bottom: 2px;
  box-shadow: none;
}

.icon-tooltip-content .form-control::-webkit-input-placeholder {
  color: #FFFFFF;
  font-style: italic;
}

.icon-tooltip-content .form-control::-moz-placeholder {
  color: #FFFFFF;
  font-style: italic;
}

.icon-tooltip-content .form-control:-ms-input-placeholder {
  color: #FFFFFF;
  font-style: italic;
}

.icon-tooltip-content .form-control:-o-input-placeholder {
  color: #FFFFFF;
  font-style: italic;
}

/*******************************************************************************
overide bootstrap - container
*******************************************************************************/
.container-fluid {
  max-width: 1200px;
}

.container .jumbotron,
.container-fluid .jumbotron {
  border-radius: 0
}

/*******************************************************************************
overide bootstrap - jumbotron
*******************************************************************************/
.jumbotron {
  background-color: inherit
}

.jumbotron h1,
.jumbotron p {
  color: #FFF
}

.jumbotron p.small {
  font-size: 18px;
}

.jumbotron p.lead {
  font-size: 25px;
}

.jumbotron h1 {
  font-size: 42px;
  margin-bottom: 0;
}

/*******************************************************************************
overide bootstrap - button
*******************************************************************************/
.btn {
  font-size: 16px;
}

.btn:focus {
  outline: none;
}

.btn,
.btn-lg {
  border-radius: 0
}

.btn-default,
.btn-default:hover {
  border-color: #AEAEAE !important;
  background-color: #fff !important;
  color: #333 !important;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:focus {
  color: #333 !important;
  background-color: #FECC0A !important;
  border-color: #e4b709 !important;
}

.btn-link,
.btn-link:focus,
.btn-link:hover {
  color: #FECC0A;
}

/*******************************************************************************
overide bootstrap - dropdown
*******************************************************************************/
.dropdown .btn-noborder {
  border: 0;
}

.dropdown .glyphicon {
  line-height: 1.42857143;
}

.dropdown-menu-block {
  width: 100%;
}

.dropdown-menu>li>span:focus,
a.dropdown-menu>li>span:hover {
  color: #262626;
  text-decoration: none;
  background-color: #f5f5f5;
}

.dropdown-menu>li>span {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
}

.form-control {
  border-radius: 0;
  border-color: #aeaeae
}

.dropdown-menu .dropdown-menu-option {
  cursor: pointer;
}

.dropdown .dropdown-menu {
  max-height: 300px;
  overflow: hidden;
  overflow-y: scroll;
}

/*******************************************************************************
overide bootstrap - page-header
*******************************************************************************/
.page-header {
  border-bottom: 1px solid #FECC0A;
  padding-bottom: 5px;
  margin-bottom: 10px;
}

.page-header h1,
.page-header h2,
.page-header h3,
.page-header h4,
.page-header h5,
.page-header h6 .page-header span {
  font-family: 'MCQGlobal_W_Rg';
  margin: 0;
}

.page-header h1.small,
.page-header h2.small,
.page-header h3.small,
.page-header h4.small,
.page-header span {
  font-size: 22px;
}

.page-header .trade-area {
  padding-top: 3px;
}

/*******************************************************************************
overide bootstrap - colume
*******************************************************************************/
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
  padding-left: 10px;
  padding-right: 10px;
}

.row {
  margin-left: 0px;
  margin-right: 0px;
}

.container-fluid {
  padding-right: 10px;
  padding-left: 10px;
}

.pageobj>.container-fluid {
  padding-right: 15px;
  padding-left: 15px;
}

/*******************************************************************************
overide bootstrap - slider
*******************************************************************************/
.slider-track-low,
.slider-track-high,
.slider-selection {
  background-color: #F3F3F4;
  background: #F3F3F4;
  border-bottom-color: #F3F3F4;
  background-image: none;
}

.slider-selection {
  box-shadow: none;
}

.slider-handle {
  background-color: #FECC0A;
  background-image: none;

}

/*******************************************************************************
overide bootstrap - slider
*******************************************************************************/
.datepicker:before {
  border: 0;
}

/*******************************************************************************
overide bootstrap - breadcrumb
*******************************************************************************/
.breadcrumb {
  background-color: inherit;
  border-radius: 0;
  margin-bottom: 0;
  padding: 0;
  margin-top: 15px;
}

.breadcrumb li,
.breadcrumb li>a {
  font-size: 18px;
  color: #333;
  line-height: normal;
}

.breadcrumb li>a:hover,
.breadcrumb li>a:focus {
  color: #333;
  text-decoration: none;
}

.breadcrumb>.active {
  color: #999;
}

/*******************************************************************************
overide bootstrap - panel-default
*******************************************************************************/
.panel-default>.panel-heading {
  color: #333;
  background-color: transparent;
  border-color: #ddd
}

/*******************************************************************************
overide bootstrap - checkbox,radio
*******************************************************************************/
.checkbox,
.radio {
  margin: 0;
}

/*******************************************************************************
common styling - a
*******************************************************************************/
a,
a:focus,
a:hover,
a:visited {
  color: #0675C2;
  text-decoration: none;
}

/*******************************************************************************
common styling - color
*******************************************************************************/
.color-primary {
  color: #FECC0A !important;
}

.color-default {
  color: #333;
}

.color-inverse {
  color: #fff;
}

.color-hyperlink {
  color: #0675C2 !important;
}

/*******************************************************************************
common styling - bgcolor
*******************************************************************************/
.bgcolor-01 {
  background-color: #E4AC76 !important;
}

.bgcolor-02 {
  background-color: #F8D98A !important;
}

.bgcolor-03 {
  background-color: #DFDBCF !important;
}

.bgcolor-04 {
  background-color: #FFEFAF;
}

.bgcolor-05 {
  background-color: #FFDB4E;
}

.bgcolor-06 {
  background-color: #FECC0A;
}

.bgcolor-07 {
  background-color: rgba(9, 124, 207, 0.2);
}

.bgcolor-08 {
  background-color: #F5F6F7 !important;
}

.bgcolor-09 {
  background-color: #F7941E !important;
}

/*******************************************************************************
common styling - searchbox
*******************************************************************************/
.searchbox .btn-default {
  border-left: 0;
  border-color: #CCCCCC;
  height: 35px;
}

.searchbox .form-control {
  height: 35px;
}

.searchbox .glyphicon {
  color: #CCCCCC;
}

.searchbox.searchbox-toppage {
  margin-top: 10px;
  margin-bottom: 30px;
}

/*******************************************************************************
common styling - loadingindicator offer by utils.js:$.fn.showloadingindicator()
*******************************************************************************/
.loadingindicator {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: url("../img/bullet/loading.gif") center center no-repeat;
  background-color: white;
  z-index: 990;
  -khtml-opacity: 0.6;
  -moz-opacity: 0.6;
  -ms-filter: "alpha(opacity=60)";
  filter: alpha(opacity=60);
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0.6);
  opacity: 0.6;
}

.nodata {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  z-index: 990;
}

.nodata table {
  width: 100%;
  height: 100%;
}

.nodata td {
  vertical-align: middle;
  text-align: center;
}

table td.loadingindicator-table {
  height: 100px;
  background: url("../img/bullet/loading.gif") center center no-repeat;
  background-color: white;
  z-index: 990;
  -khtml-opacity: 0.6;
  -moz-opacity: 0.6;
  -ms-filter: "alpha(opacity=60)";
  filter: alpha(opacity=60);
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0.6);
  opacity: 0.6;
}

/*******************************************************************************
common styling - table emptymsg
*******************************************************************************/

table .emptymsg td {
  height: 60px;
  padding: 0;
  text-align: center;
  vertical-align: middle !important;
}

/*******************************************************************************
common styling - breadcrumbbar
*******************************************************************************/
.breadcrumbbar {
  width: 100%;
  background-color: #fff;
  margin: 0 auto;
  height: 57px;
}

/*******************************************************************************
common styling - ricbarHelveticaNeueMedium
*******************************************************************************/
.ricbar {
  font-family: "MCQGlobal_W_Md";
  font-size: 24px;
  height: 36px;
  border: none;
  border-radius: 0px;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 1px 10px;
}

.ricbar>h2 {
  margin: 0;
}

/*******************************************************************************
common styling - stripe-bg
*******************************************************************************/
.stripe-bg {
  display: block;
  background: url("../img/bullet/stripe-bg.png") top left repeat-x;
  height: 198px;
  margin-bottom: -178px;
}

/*******************************************************************************
common styling - section
*******************************************************************************/
.section {
  background-color: #fff;
  padding: 40px;
  margin-top: 20px;
  width: 100%;
}

.section>.page-header {
  margin-top: 0
}

.section-p {
  /*    font-size: 16px;*/
  min-height: 80px;
}

.section .section-p:last-child {
  margin-bottom: 0;
  min-height: 0;
}

.qlink-section {
  position: relative;
  min-height: 300px;
  padding: 20px;
}

.section-qlink {
  position: relative;
  background-color: #fff;
  margin-left: -40px;
  margin-right: -40px;
  margin-bottom: -40px;
}

.section-qlink-img {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.section.advertising-banner {
  padding: 0;
}

/*******************************************************************************
quick links
*******************************************************************************/

.section-qlink-dwcalculator {
  background: url("../img/bg/qlink-bg-calculator.png") left bottom no-repeat;
  min-height: 136px;
}

.section-qlink-dwchart {
  background: url("../img/home/dwchart-tmp.png") left bottom no-repeat;
  min-height: 200px;

}

.section-qlink-dwsearch {
  background: url("../img/bg/qlink-bg-dwsearch.png") left bottom no-repeat;
  min-height: 160px;
}

.section-qlink-lmatrix {
  background: url("../img/bg/qlink-bg-lmatrix.png") left bottom no-repeat;
  min-height: 136px;
}

.section-qlink-dwterm {
  background: url("../img/bg/qlink-bg-dwterm.png") left bottom no-repeat;
  min-height: 167px;
}

.section-qlink-dwcomparison {
  background: url("../img/bg/qlink-bg-comparison.png") left bottom no-repeat;
  min-height: 124px;
}

.section-qlink-moneyflow {
  background: url("../img/bg/qlink-bg-moneyflow.png") right bottom no-repeat;
  min-height: 124px;
}

.section-qlink-dwmarketturnover {
  background: url("../img/bg/qlink-bg-dwturnover.jpg") left bottom no-repeat;
  min-height: 145px;
}

.section-qlink-turnoverdist {
  background: url("../img/bg/qlink-bg-dwturnoverdist.jpg") 0 bottom no-repeat;
  min-height: 137px;
}

.section-qlink-subscribe {
  background: url("../img/bg/qlink-bg-subscribe.png") left bottom no-repeat;
  min-height: 154px;
}

.section-qlink-todayhighlight {
  background: url("../img/bg/qlink-bg-todayhighlight.png") left bottom no-repeat;
  min-height: 154px;
}

.section-qlink-marketwrap {
  background: url("../img/bg/qlink-bg-marketwrap.png") left bottom no-repeat;
  min-height: 154px;
}

.section-qlink-seminar {
  background: url("../img/bg/qlink-bg-seminar.png") left bottom no-repeat;
  min-height: 126px;
}

.section-qlink-marketcommentary {
  background: url("../img/bg/qlink-bg-marketcommentary.png") left bottom no-repeat;
  min-height: 136px;
}

.section-qlink-investoracademy {
  background: url("../img/bg/qlink-bg-investoracademy.png") left bottom no-repeat;
  min-height: 163px;
}

.section-qlink-dwvideo {
  background: url("../img/bg/qlink-bg-dwvideo.png") center bottom no-repeat;
  min-height: 134px;
}

.section-qlink-indexdw {
  background: url("../img/bg/qlink-bg-indexdw.png") center bottom no-repeat;
  min-height: 147px;
}

.section-qlink-faq {
  background: url("../img/bg/qlink-bg-faq.png") center bottom no-repeat;
  min-height: 144px;
}

.section-qlink-achor {
  position: absolute;
  bottom: 40px;
  right: 40px;
}

.section-qlink-achor>img {
  border: none;
}

/*******************************************************************************
common styling - idangrous swiper overide
*******************************************************************************/
.newstbl .swiper-pagination-bullet,
.gallery .swiper-pagination-bullet {
  cursor: pointer;
  width: 16px;
  height: 16px;
  opacity: inherit;
  border-radius: 15px;
  margin-left: 2px;
  margin-right: 2px;
  background: url("../img/bullet/page-marker.png") 0 0 no-repeat;
  background-size: contain;
}

.newstbl .swiper-pagination-bullet.swiper-pagination-bullet-active,
.gallery .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: url("../img/bullet/page-marker-active.png") 0 0 no-repeat;
  background-size: contain;
}

.swiper-slide a,
.swiper-slide img {
  border: none;
}

#subadvertisment .swiper-pagination-bullet-active {
  background-color: #FECC0A;
}

/*******************************************************************************
common styling - footer
*******************************************************************************/
.footer {
  background-color: black;
  padding-top: 30px;
  padding-bottom: 60px;
}

.footer p {
  margin-bottom: 0
}

.footer p {
  font-size: 12px;
  color: #fff;
}

.footer hr {
  border-top: 1px solid #333;
}

.sitemap-col {
  display: inline-block;
  float: left;
  margin-left: 25px;
  margin-bottom: 10px
}

.sitemap>.sitemap-col:first-child {
  margin-left: 0;
}

.sitemap-col>ul {
  list-style-type: none;
  padding-left: 0;
}

.sitemap-col a,
.sitemap-col a:hover {
  color: #fff;
  text-decoration: none;
}

.sitemap>.sitemap-othr {
  clear: both;
}

.sitemap-corp p {
  color: #fff;
}

.sitemap-othr a,
.sitemap-othr a:hover,
.sitemap-othr-xs a,
.sitemap-othr-xs a:hover,
.sitemap-othr-sm a,
.sitemap-othr-sm a:hover {
  color: #fff;
  text-decoration: none;
}

.sitemap h3,
.sitemap a {
  font-family: "MCQGlobal_W_Md";
  /*    font-weight: bold;*/
}

.sitemap a {
  font-size: 14px;
}

.sitemap h3 {
  font-size: 16px;
  margin-bottom: 8px;
}

.sitemap-othr {
  text-align: right;
}

.sitemap-xs .sitemap-row {
  border-bottom: 1px solid #666666;

}

.sitemap-xs .sitemap-row:first-child {
  border-top: 1px solid #666666;
}

.sitemap-xs .sitemap-panel-title a,
.sitemap-xs .sitemap-panel-title a:visited,
.sitemap-xs .sitemap-panel-title a:focus,
.sitemap-xs .sitemap-panel-title a:hover {
  font-family: "MCQGlobal_W_Md";
  font-size: 13px;
  color: #FECC0A;
  background-color: inherit;
  text-decoration: none;
  outline: none !important;
  display: block;
}

.sitemap-xs .sitemap-panel-heading {
  padding: 6px;
}

.sitemap-xs .sitemap-panel-title .glyphicon {
  font-size: 8px;
  top: 0px;
  color: #666666
}

.sitemap-xs .sitemap-panel-body {
  /*    border-top: 1px solid #666666;*/
}

.sitemap-xs .btn .glyphicon {
  font-size: 8px;
  top: -1px;
  color: #666666;
  padding-left: 6px;
  padding-right: 3px;
}

.sitemap-xs .sitemap-panel-body .sitemap-row {
  border-bottom: 1px dotted #666666;
}

.sitemap-xs .sitemap-panel-body .sitemap-row:last-child {
  border-bottom: 0;
}

.sitemap-xs .sitemap-panel-body .sitemap-panel {
  padding: 5px 25px;
}

.sitemap-xs .sitemap-panel-body a,
.sitemap-xs .sitemap-panel-body a:visited,
.sitemap-xs .sitemap-panel-body a:focus,
.sitemap-xs .sitemap-panel-body a:hover {
  font-family: "MCQGlobal_W_Md";
  font-size: 13px;
  text-decoration: none;
  outline: none !important;
  display: block;
  width: 100%;
  color: #fff;
}

.sitemap-othr-xs {
  margin-left: 10px;
  margin-top: 20px;
}

.sitemap-othr-xs .sitemap-othr-col a {
  font-size: 11px;
}

.sitemap-othr-xs .sitemap-othr-col {
  display: block;
  float: left;
  padding-left: 10px;
  width: 140px;
  height: 60px;
  border-left: 1px solid #fff;
}

.sitemap-othr-xs .sitemap-othr-col.last {
  width: auto;
}

.sitemap-othr-sm {
  margin-top: 20px;
  margin-bottom: 10px;
}

/*******************************************************************************
common styling - sharebtn @sharebutton.jspf
*******************************************************************************/
.sharebtn {
  position: fixed;
  right: 0;
  top: 50%;
  margin-top: -112px;
  z-index: 999;
}

.sharebtn a,
.sharebtn button {
  margin: 0 0 5px 5px;
  height: 50px;
  width: 50px;
  display: block;
  border-radius: 8px;
}

.sharebtn i {
  font-family: FontAwesome;
  font-style: normal;
  font-size: 25px;
  display: block;
  color: #fff;
}

.sharebtn .btn:hover {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  margin: 0px 5px 8px 0px;
}

/*.sharebtn-fb{background-color:#3b5997}*/
.sharebtn-fb {
  background: url("../img/bullet/Facebook-logo-blue-circle-large-transparent-png.png") center center no-repeat;
  background-size: 50px 50px;
  /*background-color:#3b5997*/
}

.sharebtn-tl {
  background: url("../img/bullet/t_logo.png") center center no-repeat;
  background-size: 50px 50px;
  /*background-color:#3b5997*/
}

.sharebtn-yt {
  background: url("../img/bullet/Youtube-logo-png.png") center center no-repeat;
  background-size: 50px 35px;
  /*background-color:#3b5997*/
}

.sharebtn-gm {
  background-color: #c53826
}

.sharebtn-in {
  background-color: #0068c9
}

.sharebtn-twt {
  background-color: #216d9c
}

.sharebtn-ln {
  background: url("../img/bullet/line-icon.png") center center no-repeat;
  background-color: #32B900;
}

.sharebtn-hl {
  background: url("../img/bullet/icon-hotline.png") center center no-repeat;
  background-color: #fecc0a;
  /*              background-color:#32B900;*/
}

.sharebtn-sb {
  background: url("../img/bullet/subscribe_icon.png") center center no-repeat;
}

.sharebtn-icon-fb:before {
  content: "\f09a";
}

.sharebtn-icon-gm:before {
  content: "\f0d5";
}

.sharebtn-icon-in:before {
  content: "\f0e1";
}

.sharebtn-icon-twt:before {
  content: "\f099";
}

/*******************************************************************************
common styling - pageobj
*******************************************************************************/
.pageobj {
  margin-top: 76px;
  /*margin-top: 54px;*/
  margin-bottom: 30px;
}

/*******************************************************************************
common styling - page top img
*******************************************************************************/
.topimg img {
  width: 100%;
}

/*******************************************************************************
common styling - dropmenu (Decktop dropmenu 
*******************************************************************************/
.dropmenu-row {
  color: #fff;
  padding-left: 15px;
  padding-right: 15px;
  border-bottom: 1px solid #fff;
  margin-left: -10px;
  margin-right: -10px;
  line-height: 30px;
  /*height: 30px;*/
}

.dropmenu-row a,
dropmenu-row a:hover,
dropmenu-row a:visited {
  font-family: "MCQGlobal_W_Rg" !important;
  font-size: 14px !important;
  color: #fff;
  text-decoration: none;
  display: block;
}

/*******************************************************************************
common styling - navmbl, navsm (Mobile and tablet dropdown menu)
*******************************************************************************/
.navmenu.navbar-collapse {
  padding-right: 0px;
  padding-left: 0px;
  max-height: inherit;
}

.navmbl,
.navsm {
  width: 100%;
  /*    overflow-y: scroll;*/
  background-color: #666;
}

.navmbl.navbar-nav,
.navsm.navbar-nav {
  margin: 0;
}

.navmbl.navbar-nav {
  margin: 0;
}

.navmbl-row,
.navsm-row {
  border-bottom: 1px solid #999;
  padding-left: 10px;
  padding-right: 10px;
}

.navsm-row {
  border-bottom: 1px dashed #999;
}

.navmbl-row:last-child {
  border-bottom: 0px;
}

.navmblg-row {
  border-bottom: 1px solid #fff;
  padding-left: 10px;
  padding-right: 10px;
}

.navmblg-row:last-child {
  border-bottom: 0px;
}

.navmbl-toppanel,
.navsm-toppanel {
  position: relative;
  height: 40px;
  padding-top: 10px;
  padding-bottom: 5px;
}

.navmbl-right,
.navsm-right {
  float: right;
  padding-left: 6px;
  padding-right: 6px;
}

.navmbl-left,
.navsm-left {
  position: absolute;
  top: 10px;
  left: 10px;
  right: 150px;
  bottom: 5px;
  border-right: 1px solid #999;
}

.navmbl-right.bordered,
.navsm-right.bordered {
  border-right: 1px solid #999;
  padding-left: 0px;
}

.navmbl .form-control,
.navsm .form-control {
  height: 25px;
  padding: 0;
  line-height: normal;
  color: #CCCCCC;
  border: none;
  background-color: #666;
  background-image: none;
  border-radius: inherit;
  box-shadow: none;
  font-size: 16px;
}

.navmbl .btn,
.navsm .btn {
  border: none;
  background-color: inherit;
  color: #CCCCCC;
  box-shadow: none;
  font-size: 16px;
  height: 25px;
  background: #666;
}

.navmbl .btn:focus,
.navsm .btn:focus {
  outline: none;
}

.navmbl .btn .glyphicon,
.navsm .btn .glyphicon {
  top: -2px;
}

.navmbl-panel-heading,
.navsm-panel-heading {
  height: 30px;
}

.navmbl-panel-title {
  line-height: 30px;
  max-height: 280px;
}

.navmbl-panel-caret {
  float: right;
  padding-top: 8px;
  font-size: 10px;
}

.navmbl-panel-title {
  color: #CCCCCC;
  cursor: pointer;
  font-family: "MCQGlobal_W_Md";
  font-size: 15px;
}

.navmbl-panel-collapse {
  margin-left: -10px;
  margin-right: -10px;
  border-top: 1px solid #999;
}

.navmbl-panel-collapse .navmbl-panel {
  height: 30px;
  padding-left: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.navmbl-panel-collapse .navmbl-panel a,
.navmbl-panel-collapse .navmbl-panel a:hover,
.navmbl-panel-collapse .navmbl-panel a:visited {
  display: block;
  width: 100%;
  color: #CCCCCC;
  font-size: 15px;
  font-family: "MCQGlobal_W_Rg";
  text-decoration: none;
}

.navmbl-panel-collapse-mdlg {
  margin-left: -15px;
  margin-right: -15px;
  border-top: 1px solid #fff;
}

.navmbl-panel-collapse-mdlg .navmbl-panel {
  height: 30px;
  padding-left: 20px;
}

.navmbl-panel-collapse-mdlg .navmbl-panel a,
.navmbl-panel-collapse-mdlg .navmbl-panel a:hover,
.navmbl-panel-collapse-mdlg .navmbl-panel a:visited {
  display: block;
  width: 100%;
  text-decoration: none;
}

.navmbl-banner {
  padding-top: 20px;
  padding-bottom: 20px;
}

.navmbl-banner img {
  width: 100%;
  height: auto;
}

.navsm .navsm-col {
  border-right: 1px solid #999;
  font-size: 14px;
  color: #CCCCCC;
  font-family: "MCQGlobal_W_Rg";
  min-height: 210px;
  padding-left: 0;
  padding-right: 0;
}

.navsm .navsm-col:last-child {
  border-right: 0px;
}

.navsm li {
  width: 100%;
}

.navsm .row {
  padding-bottom: 30px;
  margin-left: 0;
  margin-right: 0;
}

.react-datepicker-wrapper {
  width: 100%;
}

.navsm-hpanel {
  padding: 5px 10px;
}

.navsm-panel {
  padding: 5px 20px;
}

.navsm-hpanel a,
.navsm-hpanel a:hover,
.navsm-hpanel a:visited {
  color: #CCC;
  font-family: "MCQGlobal_W_Md";
  font-size: 14px !important;
  text-decoration: none;
}

.navsm-panel a,
.navsm-panel a:hover,
.navsm-panel a:visited {
  display: block;
  width: 100%;
  color: #CCCCCC;
  font-size: 14px !important;
  font-family: "MCQGlobal_W_Rg" !important;
  text-decoration: none;
}

.navsm-right .icon-lang-xs,
.navmbl-right .icon-lang-xs {
  margin-top: 3px;
}

.css-1okebmr-indicatorSeparator {
  display: none !important;
}

/*******************************************************************************
common styling - up down txt color
*******************************************************************************/
.up {
  color: #6CC263;
}

.down {
  color: #C61C2D;
}

/*******************************************************************************
common styling - showmore (Collapsible content with panel)
*******************************************************************************/
.showmore {
  display: block;
}

.showmore-panel {
  position: relative;
  height: 25px;
  text-align: center;
  background-color: #666;
  color: #FECC0A;
  font-size: 12px;
  line-height: 25px;
}

.showmore-collapse {
  border: 1px solid #666;
  padding: 10px;
}

.showmore-icon {
  position: absolute;
  display: inline-block;
  background: url('../img/bullet/question.png') 0 0 no-repeat;
  width: 12px;
  height: 12px;
  right: 10px;
  top: 6px;
}

/*******************************************************************************
common styling - qtips
*******************************************************************************/
.qtip-default {
  border: 0;
  background-color: #FECC0A;
}

.qtip-content {
  font-size: 14px;
  line-height: normal;
}

.qtip-content p {
  margin: 0;
}

.ttip {
  color: #0675C2;
}

/*******************************************************************************
common styling - mics
*******************************************************************************/
.text-nowrap {
  white-space: nowrap;
}

/*******************************************************************************
common styling - center both image holder
*******************************************************************************/
.holder-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  justify-content: center;
}

.holder-center img {
  max-width: 100%;
  max-height: 100%;
  flex: 0 0 auto;
  object-fit: scale-down;
}

/*******************************************************************************
common styling - popup
*******************************************************************************/
#popup-image {
  display: table;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;
  text-align: center;
}

#popup-image>div {
  display: table-cell;
  vertical-align: middle;
  background: url("../img/bullet/loading.gif") center center no-repeat;
  background-color: rgba(0, 0, 0, 0.6);
}

#popup-image .inner-area {
  display: inline-block;
  position: relative;
}

#popup-customized {
  display: table;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;
}

#popup-customized>div {
  display: table-cell;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0.6);
}

#popup-customized>div>div {
  position: relative;
}

#popup-customized h5 {
  max-width: 440px;
  padding: 20px;
  margin: auto;
  text-align: center;
  background-color: white;
  color: black;
}

/*******************************************************************************
common styling - close button
*******************************************************************************/
.btn-close {
  position: absolute;
  cursor: pointer;
  top: 15px;
  right: 15px;
  width: 20px;
  height: 20px;
}

/*******************************************************************************
delay display text hr
*******************************************************************************/
.hr-hmgbm {
  margin-top: 10px;
  margin-bottom: 10px;
}

/*******************************************************************************
advertisment
*******************************************************************************/
#advertisment .videoplayer,
#subadvertisment .videoplayer {
  margin-top: 20%;
  margin-bottom: 2%;
}

#advertisment .videoplayer-container,
#subadvertisment .videoplayer-container {
  height: 0;
  width: 100%;
  padding-bottom: 50%;
  overflow: hidden;
  position: relative;
}

#advertisment iframe,
#subadvertisment iframe {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

#advertisment .btn-wapper .btn,
#subadvertisment .btn-wapper .btn {
  background-color: #00A1E0 !important;
  color: white !important;
  min-width: inherit !important;
  border-radius: 6px;

}

#advertisment .swiper-wrapper,
#subadvertisment .swiper-wrapper {
  background: url(../img/bg/VDO-bg.png) center center no-repeat;
  background-size: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

#advertisment .btn-wapper,
#subadvertisment .btn-wapper {
  text-align: right;
  margin-right: 20px;
}

/*******************************************************************************
announcements box 
*******************************************************************************/
.announcement .media-heading {
  font-size: 14px;
  font-family: 'MCQGlobal_W_Md';
  margin-bottom: 18px;
}

.announcement .media {
  margin-bottom: 30px;
}

.announcement .media-body p {
  font-size: 14px;
}

.announcement .media-readmore {
  color: #0675C2;
}

.announcement .announcement-table {
  color: #0675C2;
  font-size: 14px;
  font-family: 'MCQGlobal_W_Md';
  width: 100%;
  max-width: 200px;
  margin-left: 10px;
}

.announcement .announcement-table td {
  width: 50%;
  max-width: 100px;
}

/*******************************************************************************
common styling - fbSharebtn
*******************************************************************************/
.btn-area {
  text-align: right;
}

.fbSharebtn {
  font-style: italic;
  display: inline-block;
  width: 200px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  margin: 10px 0 10px 10px;
  cursor: pointer;
  color: white;
  background-color: #666;
  white-space: nowrap;
}

.fbSharebtn>i {
  margin-right: 20px;
}

/*******************************************
common disclaimer popupbox
*********************************************/
.disclaimer-overlay {
  position: fixed;
  overflow-y: scroll;
  display: table;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2000;
  background-color: rgba(0, 0, 0, 0.6);
  height: 100%;
  width: 100%;
}

#disclaimer-ppboxHTML .overlay-inner {
  margin: 25vh auto;
  width: 50%;
  height: auto;
  background: #000000;
  color: #ffffff;
}

#disclaimer-ppboxHTML .popupbox-content {
  padding: 30px;
}

.noscroll {
  position: relative;
  overflow: hidden;
}

#disclaimer-ppboxHTML .page-header {
  margin-top: 0px;
}

#disclaimer-ppboxHTML p,
#disclaimer-ppboxHTML h3 {
  color: #ffffff;
}

#disclaimer-ppboxHTML input {
  width: 30%;
  margin: 0 10px;
  color: #333333;
}

.disclaimer {
  cursor: pointer;
}

/*******************************************************************************
common styling - mini Warrant Calculator
*******************************************************************************/
#minidwcalc .tip_box {
  background-color: #FECC0A;
  padding: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}

#minidwcalc {
  font-size: 14px;
}

#minidwcalc .page-header {
  font-size: 16px;
}

#minidwcalc .btn {
  font-size: 14px;
}

#minidwcalc .slider.slider-horizontal {
  width: 170px;
  margin-left: 12px;
}

/*******************************************
popupbox
*********************************************/
.overlay {
  position: fixed;
  overflow-y: scroll;
  display: table;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2000;
  background-color: rgba(0, 0, 0, 0.6);
  height: 100%;
  width: 100%;
}

.overlay-inner {
  margin: 15vh auto;
  width: 80%;
  height: auto;
  background: #FFFFFF;
}

#ppboxHTML .overlay-inner,
#ppboxEligibility .overlay-inner,
#ppboxHTML2 .overlay-inner {
  margin: 25vh auto;
  width: 50%;
  height: auto;
  background: #000000;
  color: #ffffff;
}

#ppboxHTML .page-header,
#ppboxEligibility .page-header,
#ppboxHTML2 .page-header {
  margin-top: 0px;
}

#ppboxHTML p,
#ppboxHTML h3,
#ppboxEligibility p,
#ppboxEligibility h3,
#ppboxHTML2 p,
#ppboxHTML2 h3 {
  color: #ffffff;
}

#ppboxHTML input,
#ppboxEligibility input,
#ppboxHTML2 input {
  width: 30%;
  margin: 0 10px;
  color: #333333;
}

.broker_img {
  cursor: pointer;
}

.popupbox-content {
  padding: 30px;
}

/*******************************************************************************
react-datepicker
*******************************************************************************/
.input-group-addon .react-datepicker__input-container input {
  background: url('../img/bullet/icon-cal.png') no-repeat 100% 100%;
  height: 35px;
  width: 35px;
  border: 0;
  color: transparent;
}

.input-group-addon .react-datepicker__input-container input:active {
  border: 0;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
  text-align: center;
  width: 25px;
  height: 25px;
  line-height: 20px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border: none;
  font-size: 14px;
  font-family: 'MCQGlobal_W_Rg';
}

.react-datepicker__header {
  background-color: #fff !important;
  border-bottom: none !important;
}

.react-datepicker__day-names {
  margin-top: 5px;
  margin-bottom: 5px;
}

.react-datepicker__input-container input {
  text-align: center;
  width: 100%;
}

/********************** react-select  *******************/
/* .css-g1d714-ValueContainer {
  display: flex;
  align-items: center;
  justify-content: center !important;
} */

/*******************************************************************************
Responsive @media
*******************************************************************************/
@media screen and (max-width: 767px) {
  #facebook-iframe{
    flex-direction: column;
    padding-top:20px!important;
  }
  /*xs*/
  body {
    font-size: 14px;
    /*min-width: 320px;*/
    overflow-x: hidden;
  }

  .section_title span {
    font-size: 18px !important;
    margin: 0;
    margin-right: 5px
  }

  .pageobj {
    margin-top: 52px;
  }

  .searchbox.searchbox-toppage {
    margin-top: 5px;
    margin-bottom: 10px;
  }
  #todayhighlight .searchbox.searchbox-toppage .css-1xxuwq9-ValueContainer{
    overflow: unset !important;
  }
  #todayhighlight .searchbox.searchbox-toppage .css-yk16xz-control{
    padding: 2px !important;
  }
  #todayhighlight .searchbox.searchbox-toppage .css-1wa3eu0-placeholder{
    white-space: nowrap !important;
  }

  .page-header h1 {
    font-size: 18px !important;
    width: 80%;
  }

  .ricbar {
    font-size: 16px;
    height: 24px;
    text-align: center;
  }

  .btn {
    font-size: 14px;
  }

  .breadcrumbbar {
    display: none;
  }

  .navbar-toggle {
    border: 0px;
    margin-right: 12px;
    margin-left: 5px;
    padding-left: 0;
    padding-right: 0;
    color: #999;
  }

  .navbar-inverse.navbar-larger .navbar-toggle {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .navbar-inverse.navbar-larger .navbar-brand,
  .navbar-inverse.navbar-small .navbar-brand {
    font-size: 14px !important;
    height: 51px;
    padding: 0;
    padding-top: 10px;
  }

  .navbar-inverse.navbar-small .navbar-brand>img,
  .navbar-inverse.navbar-larger .navbar-brand>img {
    width: 120px;
    margin-left: 10px;
  }

  .navbar-inverse.navbar-small .navbar-nav,
  .navbar-inverse.navbar-larger .navbar-nav {
    padding-top: 0;
    padding-bottom: 0;
  }

  .sitemap-corp p {
    font-size: 8px;
    margin-top: 30px;
  }

  .footer .container-fluid {
    padding-left: 30px;
    padding-right: 30px;
  }

  .footer .sitemap-xs .btn {
    padding: 6px;
  }

  .footer .row {
    margin-right: -10px;
    margin-left: -10px;
  }

  .stripe-bg {
    margin-bottom: -198px;
  }

  .navbar-header {
    float: none;
  }

  .navbar-collapse.collapse {
    display: none !important;
  }

  .navbar-collapse.collapse.in {
    display: block !important;
  }

  .qtip-content {
    font-size: 11px;
    padding: 4px;
  }

  .fbSharebtn {
    display: block;
    width: 100%;
    margin: 10px 0;
  }
  
  #highlight .fbSharebtn {
    width: auto;
    padding: 0 8px;
  }
}
@media screen and (max-width: 540px) {
  #todayhighlight .searchbox.searchbox-toppage {
   width: 50% !important;  
  }
}
@media screen and (max-width: 470px) {
  #todayhighlight .searchbox.searchbox-toppage {
   width: 80% !important;  
  }
}
@media screen and (max-width: 410px) {
  #todayhighlight .searchbox.searchbox-toppage {
   width: 100% !important;  
  }
}
@media screen and (max-width: 380px) {
  #todayhighlight .searchbox.searchbox-toppage .css-1wa3eu0-placeholder{
    white-space: wrap !important;
  }
}
/* 右侧button */
.popup-customized {
  display: table;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;
}

.popup-customized>div {
  display: table-cell;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0.6);
}

.popup-customized>div>div {
  position: relative;
  background-color: #000;
  min-width: 300px;
  max-width: 40%;
  color: #fff;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}

.popup-customized .hlbox-close {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 20px;
  height: 20px;
  background: url(../img/bullet/btn-cls.png) center center no-repeat;
}

.popup-customized .hl-txt {
  font-family: 'MCQGlobal_W_Md';
  padding-top: 30px;
  padding-bottom: 10px;
}

.popup-customized .btn {
  font-family: 'MCQGlobal_W_Md';
  border-radius: 8px;
  position: relative;
  height: 50px;
  line-height: 36px;
  font-size: 24px;
  margin-bottom: 40px;
  min-width: 210px !important;
  margin-left: auto;
  margin-right: auto;
}

.popup-customized .hl-icon {
  display: block;
  position: absolute;
  background: url(../img/bullet/icon-hotline.png) center center no-repeat;
  width: 50px;
  height: 50px;
  top: 50%;
  margin-top: -16px;
  width: 32px;
  height: 32px;
}

.popup-customized .hl-num {
  margin-left: 35px;
}

.amcharts-chart-div a {
  display: none !important;
}

@media screen and (max-width: 991px) {
  body {
    /*position: relative;*/
    overflow-x: auto;
  }

  .pageobj {
    margin-top: 52px;
  }

  .section {
    padding: 20px 10px;
  }

  .navbar-header {
    float: none;
  }

  .navbar-toggle {
    border: 0px;
    margin-right: 12px;
    margin-left: 5px;
    padding-left: 0;
    padding-right: 0;
    color: #999;
  }

  .navbar-inverse.navbar-larger .navbar-toggle {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .navbar-inverse.navbar-larger .navbar-brand,
  .navbar-inverse.navbar-small .navbar-brand {
    font-size: 14px !important;
    height: 51px;
    padding: 0;
    padding-top: 10px;
  }

  .navbar-inverse.navbar-small .navbar-brand>img,
  .navbar-inverse.navbar-larger .navbar-brand>img {
    width: 120px;
    margin-left: 10px;
  }

  .navbar-inverse.navbar-small .navbar-nav,
  .navbar-inverse.navbar-larger .navbar-nav {
    padding-top: 0;
    padding-bottom: 0;
  }

  .breadcrumbbar {
    display: none;
  }

  .stripe-bg {
    margin-bottom: -198px;
  }

  .sitemap-corp p {
    font-size: 8px;
    margin-top: 30px;
  }

  .footer .container-fluid {
    padding-left: 30px;
    padding-right: 30px;
  }

  .footer .sitemap-xs .btn {
    padding: 6px;
  }

  .footer .row {
    margin-right: -10px;
    margin-left: -10px;
  }

  .navbar-header {
    float: none;
  }

  .navbar-collapse {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }

  .navbar-collapse.collapse {
    display: none !important;
  }

  .navbar-collapse.collapse.in {
    display: block !important;
  }

  .navbar-nav>li {
    float: none;
    height:auto;
  }

  #popup-image>div {
    background-color: black;
    padding: 0;
  }

  #disclaimer-ppboxHTML .overlay-inner {
    margin: 15vh auto;
    width: 90%;
  }

  #disclaimer-ppboxHTML .overlay-inner {
    width: 90%;
  }

  #disclaimer-ppboxHTML .overlay-inner .popupbox-content {
    padding-left: 30px;
    padding-right: 30px;
  }

  .overlay-inner .popupbox-content {
    padding-left: 30px;
    padding-right: 30px;
  }

  #ppboxHTML .overlay-inner {
    margin: 15vh auto;
    width: 90%;
  }

  .overlay-inner {
    width: 90%;
  }
}

@media screen and (max-width: 991px) {

  #ppboxHTML .overlay-inner,
  #ppboxEligibility .overlay-inner,
  #ppboxHTML2 .overlay-inner {
    width: 90%;
  }
}
@media screen and (min-width: 991px) and (max-width:1110px) {
  #SiMSCI .btn-primary{
    font-size:12px;
  }
}